import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "embryo-header-user-profile",
  templateUrl: "./HeaderUserProfileDropdown.component.html",
  styleUrls: ["./HeaderUserProfileDropdown.component.scss"],
  providers: [],
})
export class HeaderUserProfileDropdownComponent implements OnInit {
  identity;
  currentUser;
  constructor(public router: Router) {}
  activeHelper = false;
  ngOnInit() {
    //Talk about remove the verification if persists or not
    localStorage.removeItem("verification");

    let user = JSON.parse(localStorage.getItem("identity"));

    if (user.rol == "asistente_entregas") {
      this.activeHelper = true;
    }
    //Get updated user in every load
    this.identity = JSON.parse(localStorage.getItem("identity"));
    let id = this.identity.idusuario;
  }

  //log out method
  logout() {
    localStorage.removeItem("identity");
    localStorage.removeItem("token");
    localStorage.removeItem("current_user");
    //localStorage.removeItem('cart');
    localStorage.removeItem("cart_info");
    localStorage.removeItem("coordinator_selections");
    localStorage.removeItem("flow");

    this.router.navigate(["session/signin"]);
  }

  navToProfile() {
    this.router.navigate(["user_dashboard/profile"]);
  }
}
