import {
  Component,
  OnInit,
  AfterViewChecked,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ChangeDetectorRef } from "@angular/core";

//Sanitize content
import { DomSanitizer } from "@angular/platform-browser";

import { EmbryoService } from "../../Services/Embryo.service";
//Models
import { Sale } from "../../Models/sale.model";
import { SaleDetail } from "../../Models/sale_detail.model";
import { Cart } from "../../Models/cart.model";

//Services
import { CartService } from "../../Services/cart.service";
import { ShopService } from "../../Services/shop.service";
import { SaleService } from "../../Services/sale.service";
import { MessageService } from "../../Services/message.service";
import { SaleDetailService } from "../../Services/sale_detail.service";
import { CouponsService } from "../../Services/coupon.service";
import { FormControl } from "@angular/forms";
import { SnackbarService } from "../../Services/snackbar.service";
@Component({
  selector: "embryo-Cart",
  templateUrl: "./Cart.component.html",
  styleUrls: ["./Cart.component.scss"],
  providers: [
    CartService,
    ShopService,
    SaleService,
    MessageService,
    SaleDetailService,
    CouponsService,
  ],
})
export class CartComponent implements OnInit, AfterViewChecked {
  coupon_flag = true;
  discount: any = null;
  percentaje: any = null;
  products: any;
  quantityArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  popupResponse: any;
  lastprice;
  temporal_coupon: any = {};
  public products_arr = [];

  public sale: Sale;
  public sale_detail: SaleDetail;
  public cart: Cart;

  public subtotal = 0.0;

  public codeDisplay = false;

  public clickLock = true;

  public couponCtrl = new FormControl();

  constructor(
    public embryoService: EmbryoService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private loadingBar: LoadingBarService,
    private _couponService: CouponsService,
    private cdRef: ChangeDetectorRef,
    private _cartService: CartService,
    private _shopService: ShopService,
    private _saleService: SaleService,
    private _saleDetailService: SaleDetailService,
    private snackbarService: SnackbarService
  ) {
    this.sale = new Sale(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    this.sale_detail = new SaleDetail(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    this.cart = new Cart("", "", "", "", "", "", "", "", "", "", "", "", "");
  }

  ngOnInit() {
    const {
      nombre,
      apellido_paterno,
      apellido_materno,
      email,
      matricula,
      telefono,
      idusuario,
      rol,
      usuario_idescuela,
      idescuela,
    } = JSON.parse(localStorage.getItem("identity"));
    const userName = nombre;
    const { idcarrito } = JSON.parse(localStorage.getItem("cart_info"));
    this._cartService.getMyCartDetails(idusuario, idcarrito).subscribe(
      (response) => {
        //Check kind of response
        if (response.targetCartDetails) {
          this.products = response.targetCartDetails;

          if (
            rol == "coordinador" ||
            rol == "coordinador_dominio" ||
            rol == "coordinador_escuela"
          ) {
            //Push every object from my response coordinators
            response.targetCartDetails.forEach((element) => {
              this.products_arr.push(element);
              //console.log(element.precio_total)

              this.subtotal += parseFloat(element.precio_total);
              this.lastprice = this.subtotal.toFixed(2);
            });
          } else {
            //Push elements to array for customers
            this.products_arr.push(this.products);

            for (let index = 0; index < this.products_arr.length; index++) {
              const element = this.products_arr[index];

              this.subtotal += parseFloat(element.precio_total);

              this.lastprice = this.subtotal.toFixed(2);
            }
          }
        }
      },
      (error) => {
        if (error) {
          console.warn("Cart clean no items detected");
        }
      }
    );
    //console.log(user)
    let idschool = usuario_idescuela;
    if (!idschool) {
      idschool = idescuela;
    }
    this._shopService.getShop(idschool).subscribe((response) => {
      //Check kind of response
      if (response.shopTarget) {
        const {
          nombre,
          campus,
          calle,
          num_exterior,
          num_interior,
          ciudad,
          estado,
          codigo_postal,
        } = response.shopTarget;
        this.sale.entrega_escuela = nombre;
        this.sale.entrega_campus = campus;
        const address =
          calle + " " + num_exterior + "Interior: " + num_interior;
        this.sale.entrega_calle = address;
        this.sale.entrega_ciudad = ciudad;
        this.sale.entrega_estado = estado;
        this.sale.entrega_codigo_postal = codigo_postal.toString();

        this.sale.entrega_en = {
          Escuela: nombre,
          Calle: address,
          Ciudad: ciudad,
          Estado: estado,
          Código_Postal: codigo_postal.toString(),
        };
        //console.log(this.sale)

        this.sale.entrega_a = {
          Comprador: userName + " " + apellido_paterno + " " + apellido_materno,
          Correo_Electrónico: email,
          Matrícula: matricula,
          Teléfono: telefono,
        };
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  //Function for remove elements calls a service that open the modal
  public removeProduct(value: any) {
    let message = "Seguro de eliminar este producto de tu cesta ?";
    let id = value;

    this.embryoService.confirmationPopup(message, id).subscribe(
      (res) => {
        this.popupResponse = res;
      },
      (err) => console.log(err),
      () => this.getPopupResponse(this.popupResponse, value)
    );
  }

  public getPopupResponse(response, value) {
    if (response) {
      this.embryoService.removeLocalCartProduct(value);
    }
  }

  public calculateProductSinglePrice(product: any, value: any) {
    let price = 0;
    product.quantity = value;
    price = product.price * value;
    return price;
  }

  public calculateTotalPrice() {
    let subtotal = 0;
    if (
      this.embryoService.localStorageCartProducts &&
      this.embryoService.localStorageCartProducts.length > 0
    ) {
      for (let product of this.embryoService.localStorageCartProducts) {
        subtotal += product.price * product.quantity;
      }
      return subtotal;
    }
    return subtotal;
  }

  public getTotalPrice() {
    let total = 0;
    if (
      this.embryoService.localStorageCartProducts &&
      this.embryoService.localStorageCartProducts.length > 0
    ) {
      for (let product of this.embryoService.localStorageCartProducts) {
        total += product.price * product.quantity;
      }
      total += this.embryoService.shipping + this.embryoService.tax;
      return total;
    }

    return total;
  }

  public updateLocalCartProduct() {
    this.embryoService.updateAllLocalCartProduct(
      this.embryoService.localStorageCartProducts
    );
    this.router.navigate(["/checkout"]);
  }

  public getQuantityValue(product) {
    if (product.quantity) {
      return product.quantity;
    } else {
      return 1;
    }
  }

  public goToPayment() {
    let {
      rol,
      iddominio,
      usuario_idescuela,
      usuario_idprograma,
      idescuela,
      idusuario,
      nombre,
      apellido_paterno,
      apellido_materno,
      email,
      matricula,
      telefono,
    } = JSON.parse(localStorage.getItem("identity"));
    //Coupon logic
    if (!this.coupon_flag) {
      let obj = { id: this.temporal_coupon.id, c: "removeOne" };
      this._couponService.updateCoupons(obj).subscribe(
        (r) => {},
        (err) => {}
      );
    }

    // Adding coordinators
    if (
      rol == "comprador" ||
      rol == "coordinador" ||
      rol == "coordinador_dominio" ||
      rol == "coordinador_escuela"
    ) {
      let cart = JSON.parse(localStorage.getItem("cart"));

      this.sale.iddominio = iddominio;
      this.sale.usuario_idescuela = usuario_idescuela;
      if (!usuario_idescuela) {
        this.sale.usuario_idescuela = idescuela;
      }
      this.sale.usuario_idprograma = usuario_idprograma;
      this.sale.idusuario = idusuario;
      this.sale.idcarrito = cart.idcarrito;
      this.sale.modo = "PROD";
      //If sale is by code change the legend in type this cant be for users
      if (this.products_arr[0].tipo === "code") {
        this.sale.tipo = "venta_codigo";
        //Quantity of items in the cart
        this.sale.total_articulos = this.products_arr.length;
        //console.log(this.sale.total_articulos)
      } else {
        if (
          rol == "coordinador" ||
          rol == "coordinador_dominio" ||
          rol == "coordinador_escuela"
        ) {
          this.sale.tipo = "venta_coordinador";
          //Calculate articles number
          let qty = 0;
          this.products_arr.forEach((element) => {
            qty += element.cantidad;
          });
          this.sale.total_articulos = qty;
        } else {
          //Quantity of items in the cart
          this.sale.total_articulos = this.products_arr.length;

          this.sale.tipo = "venta";
        }
      }

      this.sale.tipo_rol = rol;

      this.sale.pago_concepto = cart.nombre;

      let name = nombre + " " + apellido_paterno + " " + apellido_materno;
      this.sale.comprador_nombre = name;
      this.sale.comprador_correo_electronico = email;
      this.sale.comprador_matricula = matricula;
      this.sale.comprador_telefono = telefono;
      this.sale.envio = 0.0;

      if (
        rol == "coordinador" ||
        rol == "coordinador_dominio" ||
        rol == "coordinador_escuela"
      ) {
        this.sale.subtotal = this.lastprice;
        this.sale.pago_total = this.lastprice.toString();
        this.sale.total = this.lastprice + this.sale.envio;
      } else {
        this.sale.subtotal = this.lastprice;

        let total = parseFloat(this.sale.subtotal) + this.sale.envio;

        this.sale.pago_total = this.lastprice;
        this.sale.total = this.lastprice;
      }

      this.sale.estatus_pago = 0;
      this.sale.estatus_pago_leyenda = "PENDIENTE";
      this.sale.estatus_entrega_leyenda = "SIN ENTREGAR";

      //return console.log(this.sale)
      //Send the data
      this._saleService.createSale(this.sale).subscribe((response) => {
        //console.log(response)

        //Check kind of response
        if ((response.status = "success")) {
          //Update the cart sale
          this.cart._id = cart.idcarrito;

          this.cart.subtotal_price = this.sale.subtotal;
          this.cart.id_purchase = response.saleFound.idventa;
          //Check roles
          if (
            rol == "coordinador" ||
            rol == "coordinador_dominio" ||
            rol == "coordinador_escuela"
          ) {
            this.cart.max_quantity = "n/a";
            let price = this.subtotal + this.sale.envio;
            this.cart.total_price = price.toString();
          } else {
            this.cart.max_quantity = "1";
            this.cart.total_price = this.sale.pago_total;
          }

          //Service that updates the cart with the sale
          this._cartService.updateCartSale(this.cart).subscribe((response) => {
            if ((response.status = "success")) {
              //console.log(response)
            }
          });
          //Create the sale details in the db

          // Get the existing data
          var item = JSON.parse(localStorage.getItem("identity"));

          // If no existing data, create an array
          // Otherwise, convert the localStorage string to an array
          item = item ? item : {};

          // Add new data to localStorage Array
          item["idventa"] = response.saleFound.idventa;

          // Save back to localStorage the sale id
          localStorage.setItem("identity", JSON.stringify(item));

          //Now for each element in the array create an sale detail
          for (let index = 0; index < this.products_arr.length; index++) {
            const element = this.products_arr[index];

            //console.log(element)

            this.sale_detail.idventa = response.saleFound.idventa;
            this.sale_detail.idprograma = element.idprograma;
            this.sale_detail.idarticulo = element.idarticulo;
            this.sale_detail.articulo_nivel = element.nivel;
            this.sale_detail.articulo_nombre = element.nombre;
            this.sale_detail.articulo_descripcion = element.nombre;
            this.sale_detail.cantidad = element.cantidad;
            this.sale_detail.precio = this.lastprice;
            this.sale_detail.precio_descuento = this.lastprice;
            this.sale_detail.precio_total = this.lastprice;
            this.sale_detail.isbn = element.isbn;
            this.sale_detail.serie = element.serie;

            //Service for create the details
            this._saleDetailService
              .createSaleDetail(this.sale_detail)
              .subscribe((response) => {
                //Check kind of response
                if ((response.status = "success")) {
                  let parent = JSON.parse(localStorage.getItem("parent"));

                  this.router.navigate(["checkout/payment"]);
                }
              });
          }
        } else {
          //console.log('api error')
        }
      });
    } else {
      //console.log('Cordinator right?')
    }
    //
    // let parent = JSON.parse(localStorage.getItem('parent'));
    // this.router.navigate([parent + '/checkout/payment']);
  }

  goToProducts() {
    return this.router.navigate(["productos"]);
  }

  addCoupon() {
    if (!this.coupon_flag) {
      this.snackbarService.openSnackBar("Ya has registrado un Cupón", "ok");
    }
    let val = this.couponCtrl.value;

    this._couponService.getCoupon(val).subscribe(
      (r) => {
        if (r.coupon) {
          let date = new Date();
          // console.log(r.coupon.status);

          if (r.coupon.uses < "1" || r.coupon.exp_date > date.toString()) {
            return this.snackbarService.openSnackBar(
              "Este Cupón ha caducado o no esta disponible",
              "ok"
            );
          }

          this.temporal_coupon = r.coupon;
          this.coupon_flag = false;
          let value = parseInt(r.coupon.discount.replace("%", ""));

          this.percentaje = value;
          this.discount = ((value / 100) * this.lastprice).toFixed(2);

          //console.log(this.discount);
          this.subtotal = this.lastprice - this.discount;
          //console.log(this.subtotal);

          this.lastprice = this.lastprice - this.discount;
          this.lastprice = this.lastprice.toFixed(2);
        }
      },
      (err) => {
        this.snackbarService.openSnackBar(err.error.message, "ok");
      }
    );
  }

  //Make safe url for base64
  public getSantizeUrl(url: string) {
    //Add the base 64 prompt
    return this.sanitizer.bypassSecurityTrustUrl(
      "data:image/jpg;base64," + url
    );
  }

  termsAndConditionsResponse(evt: boolean) {
    this.clickLock = !evt;
  }
}
