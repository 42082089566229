import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { UserService } from "./user.service";

@Injectable()
export class UserGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _userService: UserService
    ){}

    canActivate(){
        let identity = this._userService.getIdentity();
        
        
        if (identity) {
            //Grant access
            return true;
            
        }else{
            //From here i can send the user a dialog to register into our system
            this._router.navigate(['/session/signin']);
            
            return false;
        }
    }
}