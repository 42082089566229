import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Article } from '../Models/article.model';

import { global } from "../Config/config";

@Injectable()
export class ArticleService {
  public url: string;
  public identity;
  public token;


  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  prueba() {
    return 'Hola mundo desde el servicio de angular';
  }

  getArticlesByCategory(id_category): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url + '/get-articles-by-category/' + id_category, { headers: headers });
  }

  removeProgramArticleById(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.delete(this.url + 'delete-category-article/' + id, { headers: headers });
  }

  getArticleByName(values): Observable<any> {

    let params = values;
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url + '/get-article-by-name/', params, { headers: headers });
  }

  getArticleById(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-article-by-id/' + id, { headers: headers });
  }

  getArticleByIsbn(isbn): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-article-by-isbn/' + isbn, { headers: headers });
  }

  deleteArticleById(id): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.delete(this.url + 'delete-article/' + id, { headers: headers });
  }

  getAllArticles(body?): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url + 'get-articles/', body , { headers: headers });
  }

  createArticle(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url + 'create-article/', params, { headers: headers });
  }


  createProgramArticle(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url + 'create-category-article/', params, { headers: headers });
  }

  updateArticle(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.put(this.url + 'update-article/', params, { headers: headers });
  }

  getArticlesInDrive(): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-articles-in-drive/', { headers: headers });
  }

  createArticlesInBulk(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.post(this.url + 'create-articles-in-bulk/', params, { headers: headers });
  }


  updateArticlesInBulk(params): Observable<any> {
    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.put(this.url + 'update-program-articles-in-bulk/', params, { headers: headers });
  }

  getLevels(): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-levels/', { headers: headers });
  }

  getSeries(): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-series/', { headers: headers });
  }

  getFormats(): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion ajax
    return this._http.get(this.url + 'get-formats/', { headers: headers });
  }

  
}
