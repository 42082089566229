<div class="pt-5">
  <div class="container">
    <!-- <ngx-slick-carousel class="carousel home-3-slider" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of slides">
        <div class="text-center">
          <div class="relative">
            <img [src]="slide.img" alt="" />
            <div class="slider-content-v3">
              <div class="slider-content-v3-inner m-auto">
                <p class="text-inverse">{{ slide.content }}</p>
                <h4 class="font-bold mb-4 text-inverse">
                  {{ slide.heading_one }}
                </h4> -->
                <!-- <h2 class="mb-4 text-inverse">{{slide.heading_two}}</h2> -->
                <!-- <button mat-raised-button [routerLink]="['/products']">
                  SHOP NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel> -->
  </div>
</div>
