import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
//Get data from the other component
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
//Dialogs
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "info-modal",
  templateUrl: "ConfirmationModal.component.html",
  styleUrls: ["ConfirmationModal.component.css"],
})
export class ConfirmationModalGlobal implements OnInit {
  request: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<ConfirmationModalGlobal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  accept() {
    this.dialogRef.close();
    this._router.navigate([this.data.redirect], {
      queryParams: { parentName: this.data.name, id: this.data.id },
    });
  }

  reject() {
    this.dialogRef.close();
  }

  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
