<mat-toolbar class="header-v1">
  <mat-toolbar-row class="header-v1-top custom-header">
    <div class="container">
      <div fxLayout="row wrap" fxLayout="center center">
        <div
          fxHide.xs="true"
          fxHide.sm="true"
          fxHide.md="true"
          fxHide.md="33%"
          fxHide.lg="33%"
          fxHide.xl="33%"
        >
          <div class="list-flex">
            a
            <!-- Language and currency check -->
            <!-- <embryo-LanguageDropDown class="mr-3" [selectedValue]="embryoService?.language" (selectedLanguage)="selectedLanguage($event)"></embryo-LanguageDropDown>
            <embryo-CurrencyDropDown [selectedValue]="embryoService?.currency" (selectedCurrency)="selectedCurrency($event)"></embryo-CurrencyDropDown> -->
          </div>
        </div>
        <div
          class="logo-site"
          fxFlex.xs="40"
          fxFlex.sm="40"
          fxFlex.md="10"
          fxFlex.lg="33%"
          fxFlex.xl="33%"
        >
          <embryo-AppLogo></embryo-AppLogo>
        </div>
        <div
          fxFlex.xs="60"
          fxFlex.sm="60"
          fxFlex.md="50"
          fxFlex.lg="66%"
          fxFlex.xl="33%"
        >
          <span fxFlex></span>
          <div class="list-flex user-tool">
            <embryo-HeaderCart
              [currency]="embryoService?.currency"
              [cartProducts]="embryoService?.localStorageCartProducts"
              [count]="embryoService?.navbarCartCount"
            >
            </embryo-HeaderCart>

            <embryo-WishList
              [currency]="embryoService?.currency"
              [wishListProducts]="embryoService?.localStorageWishlist"
              [count]="embryoService?.navbarWishlistProdCount"
              (addAllWishlistToCart)="addAllWishlistToCart($event)"
              (addToCart)="addToCart($event)"
            ></embryo-WishList>

            <embryo-HeaderUserProfileDropdown></embryo-HeaderUserProfileDropdown>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
  <!-- Detect if user is loged in -->
  <mat-toolbar-row
    class="header-v1-bottom main-menu-wrap custom-menu-list"
    *ngIf="!this.guard_token"
  >
    <div class="container">
      <div class="header-v1-bottom-inner">
        <nav class="app-nav">
          <ul class="app-nav-list">
            <li class="app-nav-item">
              <ng-container>
                <a class="nav-link custom-tutorial-btn" routerLink="help/faq">
                  <i class="material-icons menu-icons">bookmarks</i>Cómo comprar
                </a>
              </ng-container>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row
    class="header-v1-bottom main-menu-wrap custom-menu-list"
    *ngIf="this.guard_token"
  >
    <div class="container">
      <div class="header-v1-bottom-inner">
        <!-- Here loads the menu -->
        <embryo-Menu></embryo-Menu>

        <!-- Search -->
        <!-- <div class="search-box">
          <button type="button" mat-fab (click)="toggleSearch()">
            <i class="material-icons notranslate">
              search
            </i>
          </button>
        </div> -->
        <!-- <div class="search-form">
          <form>
            <input type="text" placeholder="Escribe y presiona enter para buscar ">
            <button type="button" mat-fab class="close-btn" (click)="toggleSearch()">
              <i class="material-icons">
                close
              </i>
            </button>
          </form>
        </div> -->
        <button
          class="responsive-toggle"
          mat-icon-button
          (click)="toggleSidebar()"
        >
          <i class="material-icons">menu</i>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row
    class="responsive-bar relative"
    style="background-color: #3f5962 !important"
  >
    <div class="container">
      <div fxLayoutAlign="space-between">
        <button
          class="responsive-toggle"
          mat-icon-button
          (click)="toggleSidebar()"
        >
          <i class="material-icons">menu</i>
        </button>
        <div>
          <!-- <div class="search-box-bar">
                <button type="button" mat-mini-fab (click)="toggleSearch()">
                  <i class="material-icons notranslate">
                    search
                  </i>
                </button>
              </div> -->
          <!-- Search -->
          <!-- <div class="search-form">
                <form>
                  <input type="text" placeholder="Search and hit enter">
                  <button type="button" mat-fab class="close-btn" (click)="toggleSearch()">
                    <i class="material-icons">
                      close
                    </i>
                  </button>
                </form>
              </div> -->
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<embryo-FixedHeader *ngIf="this.guard_token"></embryo-FixedHeader>
