import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { Category } from '../Models/category.model';

import { global } from "../Config/config";

@Injectable()
export class CategoryService {
  public url: string;
  public identity;
  public token;


  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  prueba() {
    return 'Hola mundo desde el servicio de angular';
  }

  getCategory(id_program): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.get(this.url + 'get-category/' + id_program, { headers: headers });
  }

  getCategoriesInShop(id_school): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.get(this.url + '/list-categories/' + id_school, { headers: headers });
  }

  getAllTheCategories(): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.get(this.url + '/get-all-categories', { headers: headers });
  }

  getAllTheCategoriesInDrive(): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.get(this.url + '/get-all-categories-in-drive', { headers: headers });
  }

  createCategory(params): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.post(this.url + '/create-category', params, { headers: headers });
  }

  createCategoriesInBulk(params): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.post(this.url + '/create-programs-in-bulk', params, { headers: headers });
  }

  updateCategory(params): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.put(this.url + '/update-category', params, { headers: headers });
  }

  deleteCategory(id): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.delete(this.url + '/delete-category/' + id, { headers: headers });
  }

  getBaseDataByAccessCode(params): Observable<any> {

    //Definir cabeceras
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    // Hacer peticion https
    return this._http.post(this.url + 'get-base-data-by-code', params, { headers: headers });
  }

}
