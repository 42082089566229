<div [dir]="isRtl" (scroll)="onScrollEvent($event)">
  <mat-sidenav-container>
    <mat-sidenav #sidenav [(opened)]="embryoService.sidenavOpen" class="sidebar-area" mode="over">
      <mat-nav-list>
        <div class="close-btn-sidebar">
          <a href="javascript:void(0)" (click)="embryoService.sidenavOpen = false">
            <i class="material-icons"> close </i>
          </a>
        </div>
        <embryo-SidebarMenu *ngFor="let item of menuItems.getMainMenu()" [item]="item"></embryo-SidebarMenu>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav
      class="em-sidebar"
      #paymentSidenav
      [(opened)]="embryoService.paymentSidenavOpen"
      position="end"
      mode="over"
    >
      <embryo-PaymentDetailSideBar></embryo-PaymentDetailSideBar>
    </mat-sidenav>
    <!-- Cambiar direccion -->
    <!-- <div class="rtl-layout"><a class="text-xl" href="javascript:void(0);" (click)="changeDirection()">RTL</a></div>  -->
    <!-- <ngx-toastr></ngx-toastr> -->
    <HeaderOne *ngIf="this.currentUrl != '/home-two' && this.currentUrl != '/home-three'"></HeaderOne>
    <HeaderTwo *ngIf="this.currentUrl == '/home-two' && this.currentUrl != '/home-three'"></HeaderTwo>
    <HeaderThree *ngIf="this.currentUrl != '/home-two' && this.currentUrl == '/home-three'"></HeaderThree>
    <div class="main">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
    <FooterOne></FooterOne>
  </mat-sidenav-container>
</div>
