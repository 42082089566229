//Url for the data requests

const prod = true;

export const global = {
  url: prod
    ? "https://links2shop-backend-s38ci.ondigitalocean.app/api/ws/"
    : "http://localhost:2997/api/ws/",
  mode: "production_check_on",
};

//Production Enviroment
//https://links2shop-backend-s38ci.ondigitalocean.app/api/ws/

//Local Enviroment
//http://localhost:2997/api/ws/
