import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { termsAndConditionsComponent } from "../CommonSignIn/TermsAndConditions/termsandconditions.component";

@Component({
  selector: "terms-and-conditions",
  templateUrl: "./termsAndConditions.html",
  styleUrls: ["./termsAndConditions.css"],
})
export class TermsAndConditions implements OnInit {
  public termsAndConditions = false;
  @Output() acceptedTermsAndConditions = new EventEmitter<Boolean>(false);

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  openTermsAndConditions() {
    this.dialog.open(termsAndConditionsComponent);
  }

  handleCheckbox() {
    this.acceptedTermsAndConditions.emit(this.termsAndConditions);
  }
}
