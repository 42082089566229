<form [formGroup]="contactForm" (ngSubmit)="sendMessage()">
  <mat-form-field class="w-100">
    <input matInput placeholder="Nombre" formControlName="first_name" />
  </mat-form-field>
  <mat-error
    *ngIf="
      contactForm.controls['first_name'].hasError('required') &&
      contactForm.controls['first_name'].touched
    "
    >Este campo no puede estar vacio.</mat-error
  >
  <mat-form-field class="w-100">
    <input matInput placeholder="Apellidos" formControlName="last_name" />
  </mat-form-field>
  <mat-error
    *ngIf="
      contactForm.controls['last_name'].hasError('required') &&
      contactForm.controls['last_name'].touched
    "
    >Este campo no puede estar vacio.</mat-error
  >
  <mat-form-field class="w-100">
    <input matInput placeholder="Correo electrónico" formControlName="email" />
  </mat-form-field>
  <mat-error
    *ngIf="
      contactForm.controls['email'].hasError('required') &&
      contactForm.controls['email'].touched
    "
    >Este campo no puede estar vacio.</mat-error
  >
  <mat-error
    *ngIf="
      contactForm.controls['email'].hasError('pattern') &&
      contactForm.controls['email'].touched &&
      !contactForm.controls['email'].hasError('required')
    "
    >Please enter a valid email.</mat-error
  >

  <mat-form-field class="w-100">
    <textarea
      matInput
      placeholder="Deja tu mensaje"
      formControlName="message"
    ></textarea>
  </mat-form-field>
  <mat-error
    *ngIf="
      contactForm.controls['message'].hasError('required') &&
      contactForm.controls['message'].touched
    "
  >
    Este campo no puede estar vacio.</mat-error
  >

  <label> Adjuntar imagen
  <small>(Opcional)</small>  
  </label>
  
  <ngx-dropzone (change)="onSelect($event)" class="mb-3 mt-3">
    <ngx-dropzone-label
      >Arrastra tu imagen o haz click aquí!</ngx-dropzone-label
    >
    <ngx-dropzone-image-preview
      ngProjectAs="ngx-dropzone-preview"
      *ngFor="let f of files"
      [file]="f"
      [removable]="true"
      (removed)="onRemove(f)"
      class="animated fadeIn"
    >
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </ngx-dropzone>

  <button
    mat-raised-button
    color="accent"
    class="button-lg custom-btn-stl"
    type="submit"
  >
    Enviar Mensaje
  </button>
</form>
