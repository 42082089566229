import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "embryo-NotFound",
  templateUrl: "./NotFound.component.html",
  styleUrls: ["./NotFound.component.scss"],
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    setTimeout(() => {
      this.router.navigate(["/session/signin"]);
    }, 2500);
  }
}
