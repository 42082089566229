<ng-container *ngIf="mobWidth >= mobScreenSize; else elseButtonBlock" style="color: azure">
  <button *ngIf="this.user" mat-mini-fab [matMenuTriggerFor]="viewdropmenu" [matBadgeHidden]="hiddenBadge" matBadge="
   {{ total_items }}" matBadgePosition="after" matBadgeColor="accent" style="color: azure">
    <i class="material-icons notranslate"> shopping_cart </i>
  </button>
  <mat-menu #viewdropmenu="matMenu">
    <ng-container *ngIf="products_arr.length > 0; else elseBlock">
      <div class="drop-wrap">
        <div fxFlex="row wrap" class="cart-menu-list p-2" fxLayoutAlign="start center"
          *ngFor="let cart_element of products_arr; let indexOfelement = index">
          <div fxFlex.xs="25" fxFlex.sm="25" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25">
            <a href="
               javascript:void(0);"><img [src]="cart_element.imagen" width="60" alt="cart-image" /></a>
          </div>
          <div fxFlex.xs="75" fxFlex.sm="75" fxFlex.md="75" fxFlex.lg="75" fxFlex.xl="75" class="px-2">
            <h6 class="mb-0">
              <a href="javascript:void(0);">{{ cart_element.nombre }}</a>
            </h6>
            <span>${{ cart_element.precio_descuento }}</span>
          </div>

          <div fxFlex.xs="35" fxFlex.sm="35" fxFlex.md="35" fxFlex.lg="35" fxFlex.xl="35" class="cart-menu-action">
            <button mat-mini-fab mat-raised-button color="primary"
              (click)="confirmationPopup(cart_element.idcarrito_detalle)">
              <i class="material-icons"> remove_shopping_cart </i>
            </button>
          </div>
        </div>
      </div>
      <div class="footer-menu px-3 pb-2 pt-2">
        <button mat-raised-button color="accent" class="w-100" [routerLink]="['/carrito']">Pagar</button>
      </div>
    </ng-container>
    <ng-template #elseBlock>
      <div class="cart-menu-list p-2" fxLayoutAlign="center center">Sin productos.</div>
    </ng-template>
  </mat-menu>
</ng-container>
<ng-template #elseButtonBlock>
  <button mat-mini-fab [matBadgeHidden]="hiddenBadge" matBadge="{{ total_items }}" matBadgePosition="after"
    matBadgeColor="accent" [routerLink]="['/cart']">
    <i class="material-icons notranslate"> shopping_cart </i>
  </button>
</ng-template>