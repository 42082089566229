import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { log } from "util";
import { JsonpInterceptor } from "@angular/common/http";

@Component({
  selector: "embryo-HeaderUserProfileDropdown",
  templateUrl: "./HeaderUserProfileDropdown.component.html",
  styleUrls: ["./HeaderUserProfileDropdown.component.scss"],
})
export class HeaderUserProfileDropdownComponent implements OnInit {
  public user = false;
  public parent;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    const item = JSON.parse(localStorage.getItem("identity"));

    item ? (this.user = true) : (this.user = false);
  }

  logout() {
    localStorage.removeItem("identity");
    localStorage.removeItem("token");
    localStorage.removeItem("current_user");
    localStorage.removeItem("cart");
    localStorage.removeItem("cart_info");
    localStorage.removeItem("flow");

    this.router.navigate(["session/signin"]);
  }

  navToProfile() {
    this.router.navigate(["user_dashboard/profile"]);
  }
}
