import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
//Get data from the other component
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

//Services
import { CategoryService } from "../../../Services/category.service";

//Dialogs
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "access-code-modal",
  templateUrl: "./access-code.component.html",
  styleUrls: ["./access-code.component.css"],
  providers: [CategoryService],
})
export class accessCodeModal implements OnInit {
  access: FormGroup;
  server_code;
  request: boolean = false;
  clicked = false;
  clicked2 = false;
  constructor(
    private dialogRef: MatDialogRef<accessCodeModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formGroup: FormBuilder,
    private _categoryService: CategoryService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.access = this.formGroup.group({
      domain: ["", [Validators.required]],
      schoolAlias: ["", [Validators.required]],
      progAlias: ["", [Validators.required]],
      code: ["", [Validators.required]],
    });
  }

  async codeValidation() {
    this.request = false;
    let params = this.data;

    this._categoryService.getBaseDataByAccessCode(this.access.value).subscribe(
      (data) => {
        if (data.status === "success") {
          const body = {
            domain: data.domain,
            program: data.program,
            school: data.school,
            programs_school: data.program_school,
          };
          localStorage.setItem("storeApp", JSON.stringify(body));
          this.dialogRef.close();
          return this._router.navigate(["session/signin"]);
        }
      },
      (e) => {
        if (e.error.status === "error") {
          this.openSnackBar("El código de acceso es incorrecto", ":c");
        }
      }
    );
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    const data = pastedText.split("-");
    this.access.controls.domain.setValue(data[0]);
    this.access.controls.schoolAlias.setValue(data[1]);
    this.access.controls.progAlias.setValue(data[2]);
    this.access.controls.code.setValue(data[3]);
  }

  validateCode() {
    let typed_code = this.access.value.code;
    let server_code = this.server_code;
    let parent = JSON.parse(localStorage.getItem("parent"));

    if (typed_code == server_code) {
      //Create local storage var
      this.dialogRef.close();
      localStorage.setItem("verification", JSON.stringify(true));
      console.log(
        `El codigo de sms: ${typed_code} es igual al codigo del server: ${server_code}`
      );

      this._router.navigate(["/admin-panel/reports"]);
    } else {
      //Corrective logic
      localStorage.setItem("verification", JSON.stringify(false));
      //Clean storage and reload
      localStorage.removeItem("identity");
      localStorage.removeItem("token");
      localStorage.removeItem("current_user");
      //localStorage.removeItem('cart');
      localStorage.removeItem("cart_info");
      localStorage.removeItem("coordinator_selections");
      this.dialogRef.close();

      this._router.navigate(["/session/signin"]);

      //Acumular errores
    }

    //console.log(typed_code);
    //console.log(server_code);
  }

  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
