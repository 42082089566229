import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { UploadService } from "src/app/Services/upload.service";
//SweetAlert
import Swal from "sweetalert2";

//Model
import { Help } from "../../Models/help.model";
//Services
import { HelpService } from "../../Services/help.service";

@Component({
  selector: "embryo-ContactForm",
  templateUrl: "./ContactForm.component.html",
  styleUrls: ["./ContactForm.component.scss"],
  providers: [HelpService, UploadService],
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  emailPattern: any = /\S+@\S+\.\S+/;

  help_request: Help;
  public files: File[] = [];

  public target_user = JSON.parse(localStorage.getItem("identity"));

  constructor(
    private formGroup: FormBuilder,
    public _helpService: HelpService,
    private _uploadAservice: UploadService
  ) {
    this.help_request = new Help("", "", "", "", "", "", "", "");
  }

  ngOnInit() {
    if (this.target_user) {
       
      const name = this.target_user.nombre;
      const surnames = [
        this.target_user.apellido_paterno,
        this.target_user.apellido_materno,
      ].join(" ");
      const email = this.target_user.email;

      this.contactForm = this.formGroup.group({
        first_name: [name, { validators: [Validators.required] }],
        last_name: [surnames, { validators: [Validators.required] }],
        email: [
          email,
          {
            validators: [
              Validators.required,
              Validators.pattern(this.emailPattern),
            ],
          },
        ],
        message: ["", { validators: [Validators.required] }],
      });
    } else {
      this.contactForm = this.formGroup.group({
        first_name: ["", { validators: [Validators.required] }],
        last_name: ["", { validators: [Validators.required] }],
        email: [
          "",
          {
            validators: [
              Validators.required,
              Validators.pattern(this.emailPattern),
            ],
          },
        ],
        message: ["", { validators: [Validators.required] }],
      });
    }
  }

  public sendMessage() {
    if (this.contactForm.valid) {
      let params = this.contactForm.value;
      let fullname = params.first_name + " " + params.last_name;
      let usuario = JSON.parse(localStorage.getItem("identity"));

      this.help_request.id_user = usuario ? usuario.idusuario : 0;
      this.help_request.user_name = fullname;
      this.help_request.user_email = params.email;
      this.help_request.user_notes = params.message;
      this.help_request.status = "1";
      this.help_request.domain = this.target_user.dominio;

      if (this.files[0]) {
        const formData = new FormData();

        formData.append("file", this.files[0]);
        formData.append("upload_preset", "help_images");
        this._uploadAservice.uploadImages(formData).subscribe((res) => {
          this.help_request.image = res.secure_url;
          this.invokeSave(this.help_request);
        });
      } else {
        this.invokeSave(this.help_request);
      }
    }
  }

  invokeSave(params) {
    this._helpService.sendMessage(params).subscribe((response) => {
      if (response.status == "success") {
        Swal.fire(
          "Listo!",
          "Tu mensaje ha sido enviado, nos comunicaremos contigo pronto!",
          "success"
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error del servidor, intenta más tarde!",
        });
      }
    });
  }

  onSelect({ addedFiles }) {
    this.files.push(addedFiles[0]);
  }

  onRemove() {
    this.files = [];
  }
}
