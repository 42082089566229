import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { log } from "console";

@Injectable()
export class HelperGuard implements CanActivate {

    constructor(
        private _router: Router,
    ){}

    canActivate(){

        //Rol check
        let identity = JSON.parse(localStorage.getItem('identity'))

        if (identity.rol == 'asistente_entregas' || identity.rol == 'coordinador'  || identity.rol == 'coordinador_dominio'  || identity.rol == 'coordinador_escuela') {
            
            //Grant access
            return true;
        }else{
             //From here i can send the user a dialog to register into our system
             this._router.navigate(['/admin-panel/deliveries']);
             console.log('guard helper');
             return false;
        }
    }
}