<embryo-PageTitle
  heading="Mi carrito de compras"
  subHeading="Gestiona tus compras !"
></embryo-PageTitle>

<div class="inner-container bg-white cart-page section-gap">
  <div class="container">
    <!-- Nothing alert -->

    <div class="cart-weapper" *ngIf="this.products_arr.length == 0">
      <mat-card class="text-center">
        <h3>No hay productos en tu carrito</h3>
        <img
          src="../assets/images/empty-cart.png"
          class="animated lightSpeedIn delay-4s"
        />
        <div class="text-center">
          <button
            mat-raised-button
            color="accent"
            class="button-lg"
            (click)="goToProducts()"
          >
            Ir a la tienda
          </button>
        </div>
      </mat-card>
    </div>
    <!--  -->
    <div class="cart-wrapper" *ngIf="this.products_arr.length > 0">
      <div
        class="cart-shop-list mb-5 text-center card-shadow"
        *ngFor="let article of products_arr"
      >
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="cart-item">
          <div
            fxFlex.xs="100"
            fxFlex.sm="100"
            fxFlex.md="10"
            fxFlex.lg="20"
            fxFlex.xl="20"
            class="cart-product-image"
          >
            <div class="cart-item-thumb">
              <a href="javascript:void(0)" class="p-link">
                <div *ngIf="article.tipo == 'physic'">
                  <img
                    width="626"
                    height="800"
                    class="card-shadow"
                    [src]="article.imagen"
                  />
                </div>
                <div *ngIf="article.tipo == 'code'">
                  <img
                    width="626"
                    height="800"
                    class="card-shadow"
                    [src]="article.imagen_virtual"
                  />
                </div>
              </a>
            </div>
          </div>
          <div
            fxFlex.xs="100"
            fxFlex.sm="100"
            fxFlex.md="90"
            fxFlex.lg="80"
            fxFlex.xl="80"
          >
            <div
              fxLayout="row wrap"
              fxLayoutAlign="center center"
              class="cart-product-info"
            >
              <div
                fxFlex.xs="100"
                fxFlex.sm="40"
                fxFlex.md="30"
                fxFlex.lg="40"
                fxFlex.xl="40"
              >
                <h5>Entrega</h5>
                <a class="mb-0" *ngIf="article.tipo == 'physic'">
                  La entrega se hara en las instalaciones de tu escuela.
                  <p>Mantente en contacto con el coordinador.</p>
                </a>

                <a class="mb-0" *ngIf="article.tipo == 'code'">
                  La entrega se realizara en tu correo electrónico.
                  <p>Una vez pagues, revisa tu bandeja de entrada.</p>
                </a>
              </div>
              <div
                fxFlex.xs="50"
                fxFlex.sm="20"
                fxFlex.md="20"
                fxFlex.lg="20"
                fxFlex.xl="20"
              >
                <mat-form-field class="w-100">
                  <input
                    matInput
                    type="number"
                    min="1"
                    max="10"
                    placeholder="{{ article.cantidad }}"
                    disabled
                  />
                </mat-form-field>
              </div>
              <div
                fxFlex.xs="50"
                fxFlex.sm="40"
                fxFlex.md="30"
                fxFlex.lg="20"
                fxFlex.xl="20"
              >
                <div class="cart-item-price">
                  <h4>${{ article.precio_total }}</h4>
                </div>
              </div>
              <div
                class="close-sm"
                fxFlex.xs="100"
                fxFlex.sm="100"
                fxFlex.md="20"
                fxFlex.lg="20"
                fxFlex.xl="20"
              >
                <div class="cart-item-price">
                  <a
                    href="javascript:void(0)"
                    class="accent-color remove-cart"
                    (click)="removeProduct(article.idcarrito_detalle)"
                    ><i class="material-icons"> close </i></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="cart-total"
        fxLayout="row wrap"
        fxLayoutAlign="flex-end"
        *ngIf="this.products_arr.length > 0"
      >
        <div
          fxFlex.xs="100"
          fxFlex.sm="100"
          fxFlex.md="40"
          fxFlex.lg="40"
          fxFlex.xl="40"
        >
          <hr class="spacer" />
          <p align="center">Añade tu Cupón y recibe un descuento !</p>
          <div>
            <form>
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Agrega tu cupon aquí </mat-label>
                <input
                  matInput
                  type="text"
                  aria-label="Parent"
                  [formControl]="couponCtrl"
                />
              </mat-form-field>
            </form>

            <span fxFlex></span>
            <button
              class="m-2"
              style="background-color: #ea80fc; color: #fafafa"
              mat-raised-button
              (click)="addCoupon()"
            >
              Agregar Cupón
            </button>
          </div>
          <p align="center" style="color: darkcyan" *ngIf="discount">
            Tu descuento del {{ percentaje }}% ha sido aplicado!
          </p>
          <hr class="spacer" />
          <div>
            <p>Subtotal</p>
            <span fxFlex></span>${{ this.subtotal }}
          </div>
          <div>
            <p>Envio</p>
            <span fxFlex></span> $0.00
          </div>
          <hr class="spacer" />
          <div class="product-detail-price mb-4">
            <h4>Total</h4>
            <span fxFlex></span>
            <h4 class="accent-color">${{ this.lastprice }}</h4>
          </div>
          <div>
            <terms-and-conditions
              (acceptedTermsAndConditions)="termsAndConditionsResponse($event)"
            ></terms-and-conditions>
          </div>
          <div>
            <span fxFlex></span
            ><button
              [disabled]="clickLock"
              mat-raised-button
              color="accent"
              class="button-lg custom-btn-stl"
              (click)="goToPayment(); clickLock = true"
            >
              Pagar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
