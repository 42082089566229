<mat-card class="mb-4">
  <h2 mat-dialog-title>
    <i class="fas fa-file-invoice" style="color: blueviolet"></i> Terminos y
    condiciones
  </h2>
</mat-card>

<mat-dialog-content class="mat-typography">
  <mat-card>
    <p>
      <strong>Links2Shop</strong> es una tienda en línea operada por
      <strong>I VISION ON SA DE CV</strong> con domicilio en Avenida Teopanzolco 406 -2B, colonia Reforma, Cuernavaca, Morelos y RFC IVO 1107014A3. iVisionOn es agente exclusivo de la editorial Helbling en las siguientes regiones: México, América Central, Panamá y el Caribe. Links2Shop es un servicio de compra en línea de libros educativos de texto en su versión impresa y/o digital, disponible exclusivamente para alumnos y personal de universidades y entidades educativas en México, América Central, Panamá y el Caribe, y quienes hayan suscrito un convenio para tal efecto con Helbling y/o con iVisionOn.
    </p>

    <ol>
      <li>
        <strong>Privacidad</strong><br /><br />
        <strong>iVisionOn</strong> cumple con las leyes de protección de datos y privacidad vigentes en México y con las leyes de la Unión Europea. Garantizamos la confidencialidad y privacidad de la información personal que usted voluntariamente proporcione en nuestro sitio.
      </li>
      <br />
      <li>
        <strong>Registro</strong><br /><br />
        El usuario del sitio debe de registrar datos específicos antes de poder realizar compras. Éstos incluyen nombre, correo electrónico, nombre de la institución, facultad y/o el plantel donde realiza sus estudios, entre otros. En todo momento, el usuario tendrá acceso a su información, misma que podrá editar o solicitar que sea removida de la plataforma al personal de Helbling y/o <strong>iVisionOn</strong>.
      </li>
      <br />
      <li>
        <strong>Fechas de compra</strong><br /><br />
        El servicio de compra de libros impresos a través de este sitio estará disponible sin costo de envío, siempre y cuando respete el rango de fechas que cada universidad o entidad educativa solicite a <strong>iVisionOn</strong>.
        Fuera de estos periodos, los costos y condiciones podrán sufrir cambios y/o causar costos de envío.
      </li>
      <br />
      <li>
        <strong>Precios</strong><br /><br />
        Los precios, promociones y la disponibilidad de los productos, están sujetos a cambio sin previo aviso y sólo aplican para ventas en línea. Los precios son regulados mediante los convenios firmados entre Helbling México y/o <strong>iVisionOn</strong> con las universidades e instituciones educativas. Todos los precios están expresados en pesos mexicanos.
      </li>
      <br />
      <li>
        <strong>Pagos</strong><br /><br />
        El usuario puede elegir entre diferentes formas de pago. Para su seguridad, se ofrecen como formas de pago los módulos proporcionados por Mercado Pago, Paypal y Conekta. El servidor no guarda datos de tarjetas de crédito, débito o datos bancarios del cliente. De igual manera nunca solicita que se envíen los datos de las tarjetas por correo electrónico. Los pagos realizados en efectivo tienen una acreditación de 24 a 48 horas y la compra será confirmada hasta recibir la acreditación del pago.
      </li>
      <br />
      <li>
        <strong>Entrega</strong><br /><br />
        Las compras de los usuarios serán entregadas según su tipo:  A. Compras de códigos Digi pack serán enviadas a la dirección de correo electrónico registrado por el usuario al momento de su registro. B.Compra libro físico: los libros serán enviados al plantel de la institución seleccionada por el usuario como lugar de estudios para su posterior entrega. La institución designará a un representante como el responsable de la entrega de las compras contra los comprobantes de pago y comprobación de identidad del comprador.
      </li>
      <br />
      <li>
        <strong>Fechas de entrega</strong><br /><br />
        <p>
          A. Compra de códigos Digi pack: La entrega se realizará inmediatamente
          al correo electrónico del comprador después de la confirmación del
          pago.
        </p>
        <p>
          B.Compra libro físico:Una vez acreditado su pago, la compra será
          enviada a la dirección del plantel de la universidad y/o escuela o
          registrada en la plataforma por el usuario. El comprador podrá recoger
          su libro en las fechas y mediante los procesos internos definidos por
          la institución.
        </p>
      </li>
      <br />
      <li>
        <strong>Devoluciones</strong><br /><br />
        Debido a que los libros tienen un código de acceso a la plataforma de
        aprendizaje de Helbling, eZone, una vez entregado el libro no se aceptan
        devoluciones con excepción únicamente en casos de libros defectuosos.

        <br />
        <br />
        <ul>
          <li>
            Los libros defectuosos deberán ser reportados a la institución a la
            que pertenece el alumno, y/o a
            <strong>iVisionOn</strong> SA de CV, Sucursal Virtual en
            webshophelp@ivisionon.com. Una vez acreditada su devolución, se realizará
            la reposición inmediata del mismo libro sin costo de flete.
          </li>
          <li>
            No será posible efectuar devoluciones totales o parciales de
            E-books, debido a los formatos y métodos de envío que se emplearán
            para su distribución.
          </li>
          <br />
          <li>
            No será posible efectuar devoluciones totales o parciales de
            E-books, debido a los formatos y métodos de envío que se emplear
            para su distribución.
          </li>
        </ul>
      </li>
    </ol>
    <p>
      <strong>iVisionOn</strong> se esfuerza para presentar la información precisa de todo el material ofrecido dentro del sitio web, no obstante, se reserva el derecho a cancelar, parcial o totalmente, algún pedido por imprecisiones y/o errores en la información proporcionada por el usuario, así como por problemas identificados por nuestro departamento de crédito y prevención de fraudes. Si su pedido es cancelado después de que el cargo haya sido generado, el cliente será notificado y se procederá con el reembolso del mismo. A fin de ofrecer un mejor servicio, iVisionOn podrá efectuar modificaciones en cualquier momento o cuando las considere convenientes, para realizar mejoras, correcciones o actividades de mantenimiento en la presentación, contenido, información o servicios. Al aceptar los Términos y Condiciones de este sitio web así como de los servicios ofrecidos dentro del mismo, significa que ha leído, entendido y está de acuerdo con los puntos y términos antes aquí descritos. En caso contrario, deberá abstenerse de proporcionar información alguna, acceder y/o utilizar cualquier servicio ofrecido dentro de nuestro sitio. Ante cualquier duda, favor de contactarnos en: webshophelp@ivisionon.com 
    </p>
  </mat-card>
</mat-dialog-content>
