import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { global } from "../Config/config";

@Injectable()
export class UploadService {
  public url: string;
  public identity;
  public token;

  constructor(private _http: HttpClient) {
    this.url = global.url;
  }

  getFilesByShop(id): Observable<any> {
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.get(this.url + "get-files-by-shop/" + id, {
      headers: headers,
    });
  }

  uploadFileUsers(vals): Observable<any> {
    let data = vals;

    // Hacer peticion ajax
    return this._http.post(
      "https://api.cloudinary.com/v1_1/ivision/raw/upload",
      data
    );
  }

  uploadImages(data): Observable<any> {

    // Hacer peticion ajax
    return this._http.post(
      "https://api.cloudinary.com/v1_1/ivision/image/upload",
      data
    );
  }

  saveNewUsersList(vals): Observable<any> {
    let params = vals;
    //Definir cabeceras
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );

    // Hacer peticion ajax
    return this._http.post(this.url + "upload-new-user-list", params, {
      headers: headers,
    });
  }
}
