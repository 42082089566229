<div fxLayout="row wrap">
 
  <mat-checkbox class="mb-3 mr-2" [(ngModel)]="termsAndConditions"
  (ngModelChange)="handleCheckbox()">Acepto terminos y condiciones</mat-checkbox
  >


  <a class="btn-anim mb-3" (click)="openTermsAndConditions()">
    (ver términos y condiciones)
  </a>

</div>