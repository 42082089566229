import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
//Get data from the other component
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
//Dialogs
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "info-modal",
  templateUrl: "InfoModal.component.html",
  styleUrls: ["InfoModal.component.css"]
})
export class InfoModalModal implements OnInit {

  request: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<InfoModalModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    console.log(this.data);
    
  }

  cleanLogic() {
    localStorage.clear();
    localStorage.setItem("version","2.1");
    this._router.navigate(['/session/signin']);
  }
  
  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
