<form [formGroup]="_login">
  <div class="mb-4">
    <mat-form-field class="w-100">
      <input
        matInput
        type="email"
        placeholder="Email"
        formControlName="email"
        required
      />
      <mat-error>Completa los campos de manera correcta.</mat-error>
    </mat-form-field>
  </div>
  <div class="mb-4">
    <div fxLayout="row wrap">
      <mat-form-field class="w-100">
        <input
          matInput
          type="password"
          placeholder="Password"
          formControlName="password"
          required
        />
        <mat-error>Completa los campos de manera correcta.</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<terms-and-conditions
  (acceptedTermsAndConditions)="termsAndConditionsResponse($event)"
></terms-and-conditions>

<div class="text-center">
  <button
    class="custom-login-btn button-lg mb-3"
    type="submit"
    mat-raised-button
    (click)="login()"
  >
    Login
  </button>
  <button
    mat-stroked-button
    class="custom-register-btn button-lg mb-3 ml-4"
    type="submit"
    (click)="navigateToSignup()"
  >
    Registrarme
  </button>
</div>
<!-- <p>No tienes una cuenta? <a (click)="navigateToSignup()" class="pointer"> Haz click en registrarme</a></p> -->

<div align="center">
  <div class="mb-3">
    <a routerLink="/session/forgot-password">¿Olvidaste tu contraseña?</a>
  </div>
</div>
