import { Injectable } from '@angular/core'

/*
 * Menu interface
 */
export interface Menu {
  state: string
  name?: string
  type?: string
  icon?: string
  children?: Menu[]
}

let HeaderOneItems = []
let HeaderOneItemsCordi = []
let FooterOneItems = []

setTimeout(() => {

  HeaderOneItems = [
    // {
    //   state: 'user_dashboard/profile',
    //   name:"Home",
    //   type:"link",
    //   icon: 'house'
    // },
    {
      //type : sub -> to child logic
      state: 'productos',
      name: 'Tienda',
      type: 'link',
      icon: 'store',
      // children: [
      //   {
      //     state: 'productos',
      //     name: 'Productos',
      //     type: 'link',
      //     icon: 'book'
      //   },
      //   {
      //     state: 'carrito',
      //     name: 'CARRITO',
      //     type: 'link',
      //     icon: 'shopping_cart'
      //   },
      // 	{
      //     state: 'checkout/payment',
      //     name: 'PAGO',
      //     type: 'link',
      //     icon: 'payment'
      //   },
      //   {
      //     state: 'checkout/delivery',
      //     name: 'Entrega',
      //     type: 'link',
      //     icon: 'local_shipping'
      //   }
      // ]
    },
    {
      state: 'help/faq',
      name: 'Tutoriales',
      type: 'link',
      icon: 'bookmarks',
    },
    {
      state: 'help/about',
      name: 'Ayuda',
      type: 'link',
      icon: 'contact_support',
    },
    {
      state: 'user_dashboard/profile',
      name: 'Mi cuenta',
      type: 'link',
      icon: 'home',
    },
  ]

  HeaderOneItemsCordi = [
    // {
    //   state: 'user_dashboard/profile',
    //   name:"Home",
    //   type:"link",
    //   icon: 'house'
    // },
    {
      state: 'productos',
      name: 'Tienda',
      type: 'link',
      icon: 'store',
      // children: [
      //   {
      //     state: "productos",
      //     name: "Productos",
      //     type: "link",
      //     icon: "book",
      //   },
      //   {
      //     state: "carrito",
      //     name: "CARRITO",
      //     type: "link",
      //     icon: "shopping_cart",
      //   },
      //   {
      //     state: "checkout/payment",
      //     name: "PAGO",
      //     type: "link",
      //     icon: "payment",
      //   },
      //   {
      //     state: "checkout/delivery",
      //     name: "Entrega",
      //     type: "link",
      //     icon: "local_shipping",
      //   },
      // ],
    },
    {
      state: 'help/faq',
      name: 'Tutoriales',
      type: 'link',
      icon: 'bookmarks',
    },
    {
      state: 'help/about',
      name: 'Ayuda',
      type: 'link',
      icon: 'contact_support',
    },
    {
      state: 'admin-panel',
      name: 'ADMIN PANEL',
      type: 'link',
      icon: 'supervised_user_circle',
    },
  ]

  FooterOneItems = [
    {
      state: '',
      name: 'ABOUT',
      type: 'sub',
      icon: '',
      children: [
        {
          state: 'about',
          name: 'ABOUT',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'term-condition',
          name: 'TERM AND CONDITION',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'privacy-policy',
          name: 'PRIVACY POLICY',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'faq',
          name: 'FAQ',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'contact',
          name: 'CONTACT US',
          type: 'link',
          icon: 'perm_contact_calendar',
        },
      ],
    },
    {
      state: '',
      name: 'SESSION',
      type: 'sub',
      icon: '',
      children: [
        {
          state: 'session/signin',
          name: 'SIGN IN',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'session/signup',
          name: 'REGISTER',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'session/forgot-password',
          name: 'FORGET PASSWORD',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'session/thank-you',
          name: 'THANK YOU',
          type: 'link',
          icon: 'arrow_right_alt',
        },
      ],
    },
    {
      state: '',
      name: 'CATEGORIES',
      type: 'sub',
      icon: '',
      children: [
        {
          state: 'products/women',
          name: 'WOMEN',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'products/men',
          name: 'MEN',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'products/accesories',
          name: 'ACCESSORIES',
          type: 'link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'products/gadgets',
          name: 'GADGETS',
          type: 'link',
          icon: 'arrow_right_alt',
        },
      ],
    },
    {
      state: '',
      name: 'SOCIAL',
      type: 'sub',
      icon: '',
      children: [
        {
          state: 'https://www.facebook.com/',
          name: 'Facebook',
          type: 'social_link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'https://twitter.com/',
          name: 'Twitter',
          type: 'social_link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'https://www.youtube.com/',
          name: 'Youtube',
          type: 'social_link',
          icon: 'arrow_right_alt',
        },
        {
          state: 'https://plus.google.com',
          name: 'Google Plus',
          type: 'social_link',
          icon: 'arrow_right_alt',
        },
      ],
    },
  ]

}, 2500);




@Injectable()
export class MenuItems {
  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {
    return HeaderOneItems
  }

  getMainMenuCordi(): Menu[] {
    return HeaderOneItemsCordi
  }

  /*
   * Get all footer menu
   */
  getFooterOneMenu(): Menu[] {
    return FooterOneItems
  }
}
