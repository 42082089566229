import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router'
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser'

import { EmbryoService } from '../../Services/Embryo.service'

import { CartService } from '../../Services/cart.service'
import { log } from 'console'

@Component({
  selector: 'embryo-HeaderCart',
  templateUrl: './HeaderCart.component.html',
  styleUrls: ['./HeaderCart.component.scss'],
  providers: [CartService, EmbryoService],
})
export class HeaderCartComponent implements OnInit, OnDestroy {
  @Input() cartProducts: any
  @Input() count: any
  @Input() currency: string
  public cart
  mobWidth: any
  mobScreenSize: number = 767
  popupResponse: any
  public products
  public products_arr = []
  public parent

  public total_items: number = 0

  mySubscription: any

  public user = false

  @Output() removeProductData: EventEmitter<any> = new EventEmitter()

  //hiddenBadge = true;

  constructor(
    public embryoService: EmbryoService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private _cartService: CartService
  ) {
    this.mobWidth = window.screen.width

    //Refresh any component with this code
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false
    }
    this.mySubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false
      }
    })
    //Refresh any component with this code
  }

  ngOnInit() {
    let item = JSON.parse(localStorage.getItem('identity'))
    let cart_info = JSON.parse(localStorage.getItem('cart_info'))
    this.parent = JSON.parse(localStorage.getItem('storeApp')).domain.nombre;
    //Check if we have the user in the current local storage
    if (item && cart_info) {
      this.user = true

      this._cartService.getMyCartDetails(item.idusuario, cart_info.idcarrito).subscribe(
        response => {
          //Check kind of response
          if (response.status == 'success') {
            if (item.rol == 'coordinador' || item.rol == 'coordinador_dominio' || item.rol == 'coordinador_escuela') {
              let params = response.targetCartDetails
              //Push every object from my response coordinators
              params.forEach(element => {
                this.products_arr.push(element)
                //Show number if items in the cart
                this.total_items += 1
              })
            } else {
              this.products = response.targetCartDetails

              //Push elements to array
              this.products_arr.push(this.products)

              this.count = this.products_arr.length

              this.total_items += 1
            }
          } else {
            this.count = 0
          }
        },
        error => {
          if (error) {
            console.warn('This user doesnt posses items in his cart yet')
          }
        }
      )
    } else {
      this.user = false

      return
    }
  }

  public confirmationPopup(product: any) {
    let message = 'This comes from the header cart'
    let id = product

    this.embryoService.confirmationPopup(message, id).subscribe(
      res => {
        this.popupResponse = res
      },
      err => console.log(err)
    )
  }

  public getPopupResponse(response, value) {
    if (response) {
      this.embryoService.removeLocalCartProduct(value)
    }
  }

  public calculatePrice(product) {
    let total = null
    total = product.price * product.quantity
    return total
  }

  //Make safe url for base64
  public getSantizeUrl(url: string) {
    //Add the base 64 prompt
    return this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,' + url)
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe()
    }
  }
}
