<h1 mat-dialog-title>
  <span class="material-icons" style="vertical-align: middle"> lock_open </span>
  Access code
</h1>
<div mat-dialog-content align="center">
  <p><mat-label> Ingresa tu código de accesso </mat-label></p>
  <mat-form-field class="example-full-width mt-4">
    <input
      matInput
      placeholder="Paste your code here."
      required
      (paste)="onPaste($event)"
    />
  </mat-form-field>
  <form [formGroup]="access">
    <div class="mb-4">
      <mat-form-field class="example-full-width mt-4" appearance="outline">
        <input
          matInput
          #domain
          formControlName="domain"
          required
        />
      </mat-form-field>
      -
      <mat-form-field class="example-full-width mt-4" appearance="outline">
        <input
          matInput
          #schoolName
          formControlName="schoolAlias"
          required
        />
      </mat-form-field>
      -
      <mat-form-field class="example-full-width mt-4" appearance="outline">
        <input
          matInput
          #progAlias
          formControlName="progAlias"
          value=""
          required
        />
      </mat-form-field>
      -
      <mat-form-field class="example-full-width mt-4" appearance="outline">
        <input
          matInput
          #code
          formControlName="code"
          value=""
          required
        />
      </mat-form-field>
    </div>
  </form>
  <button mat-raised-button color="accent" (click)="codeValidation()">
    Validar
  </button>
</div>
