import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes } from "@angular/router";

import { UserGuard } from "./Services/user.guard";

import { MainComponent } from "./Main/Main.component";
import { CartComponent } from "./Pages/Cart/Cart.component";
import { NotFoundComponent } from "./Pages/NotFound/NotFound.component";
import { ClosedShopComponent } from "./Pages/ClosedShop/closedShop.component";
import { ShopRequestModuleComponent } from "./Pages/ShopRequestModule/shopRequestModule.component";
import { DeliveryComponent } from "./Pages/delivery/delivery.component";
import { OrderRequestComponent } from "./Pages/order-request/order-request.component";
import { OrderRequestPlComponent } from "./Marketing/Forms/order-request-pl/order-request-pl.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "session/signin",
    pathMatch: "full",
  },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "session",
        loadChildren: () =>
          import("./Pages/Session/Session.module").then((m) => m.SessionModule),
      },
      {
        path: "user_dashboard",
        loadChildren: () =>
          import("./Pages/UserAccount/UserAccount.module").then(
            (m) => m.UserAccountModule
          ),
      },
      {
        path: "productos",
        loadChildren: () =>
          import("./Pages/Products/Products.module").then(
            (m) => m.ProductsModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: "carrito",
        component: CartComponent,
        canActivate: [UserGuard],
      },
      {
        path: "entrega",
        component: DeliveryComponent,
        canActivate: [UserGuard],
      },
      {
        path: "not-found",
        component: NotFoundComponent,
      },
      {
        path: "sale_off",
        component: ClosedShopComponent,
      },
      {
        path: "shop_request/:id",
        component: ShopRequestModuleComponent,
      },
      {
        path: "order-request/:id",
        component: OrderRequestComponent,
      },
      {
        path: "order-request-pipeline/:id",
        component: OrderRequestPlComponent,
      },
      {
        path: "checkout",
        loadChildren: () =>
          import("./Pages/Checkout/Checkout.module").then(
            (m) => m.CheckoutModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: "help",
        loadChildren: () =>
          import("./Pages/About/About.module").then((m) => m.AboutModule),
      },
      {
        path: "blogs",
        loadChildren: () =>
          import("./Pages/Blogs/Blogs.module").then((m) => m.BlogsModule),
      },
    ],
  },
  { path: ":parent", component: MainComponent },
  // {
  //   path: "**",
  //   redirectTo: "not-found",
  // },
];
