import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-menu-list-items",
  templateUrl: "./MenuListItems.component.html",
  styleUrls: ["./MenuListItems.component.css"],
})
export class MenuListItemsComponent implements OnInit {
  public menuItems;

  constructor() {}

  ngOnInit() {
    let MENUITEMS = [];

    let flow = JSON.parse(localStorage.getItem("flow"));
    let user = JSON.parse(localStorage.getItem("identity"));
    if (user.rol != "master") {
      MENUITEMS.push({
        state: "/admin-panel/reports",
        name: "Mi dashboard",
        type: "link",
        icon: "home",
      });
    }
    if (user.rol == "master") {
      MENUITEMS.push({
        state: "admin-panel/master",
        name: "Mi dashboard",
        type: "link",
        icon: "home",
      });
      MENUITEMS.push({
        state: "admin-panel/master/users-module",
        name: "Usuarios",
        type: "link",
        icon: "group",
      });
      MENUITEMS.push({
        state: "admin-panel/master/parents-module",
        name: "Parents",
        type: "link",
        icon: "domain",
      });
      MENUITEMS.push({
        state: "admin-panel/master/shops-module",
        name: "Escuelas",
        type: "link",
        icon: "store_mall_directory",
      });
      MENUITEMS.push({
        state: "admin-panel/master/programs-module",
        name: "Programas",
        type: "link",
        icon: "category",
      });
      MENUITEMS.push({
        state: "admin-panel/master/articles-module",
        name: "Articulos",
        type: "link",
        icon: "library_books",
      });
      MENUITEMS.push({
        state: "admin-panel/master/sales-module",
        name: "Ventas",
        type: "link",
        icon: "monetization_on",
      });
      MENUITEMS.push({
        state: "admin-panel/master/codes-module",
        name: "Códigos",
        type: "link",
        icon: "mark_email_unread",
      });
      MENUITEMS.push({
        state: "admin-panel/master/credentials-module",
        name: "Credenciales",
        type: "link",
        icon: "account_balance_wallet",
      });
      MENUITEMS.push({
        state: "admin-panel/master/history",
        name: "Historial",
        type: "link",
        icon: "history",
      });
      MENUITEMS.push({
        state: "admin-panel/master/tickets",
        name: "Tickets",
        type: "link",
        icon: "book_online",
      });
      MENUITEMS.push({
        state: "admin-panel/master/config",
        name: "Config",
        type: "link",
        icon: "settings",
      });
      MENUITEMS.push({
        state: "admin-panel/master/orders-module",
        name: "Ordenes",
        type: "link",
        icon: "list_alt",
      });
      MENUITEMS.push({
        state: "admin-panel/master/coupons-module",
        name: "Cupones",
        type: "link",
        icon: "local_activity",
      });
    }

    //Work on this
    if (user.rol == "l2a_manager") {
      MENUITEMS.push({
        state: "admin-panel/general-dashboard",
        name: "Admin Dashboard",
        type: "link",
        icon: "local_shipping",
      });

      //Add this view
      MENUITEMS.push({
        state: "admin-panel/agent-view",
        name: "Agent Dashboard",
        type: "link",
        icon: "admin_panel_settings",
      });
    }

    if (flow) {
      //Menu First Escenario Flow A
      if (
        flow.flow_request == "Just_Books" ||
        flow.flow_request == "Just_Temporal_&_Book" ||
        flow.flow_request == "Just_Hybrid"
      ) {
        MENUITEMS.push({
          state: "admin-panel/deliveries",
          name: "Mis entregas",
          type: "link",
          icon: "local_shipping",
        });
        MENUITEMS.push({
          state: "admin-panel/helpers",
          name: "Mis ayudantes",
          type: "link",
          icon: "supervised_user_circle",
        });
      }
      //Menu First Escenario Flow B
      if (flow.flow_request == "Just_Codes") {
      }

      //Menu First Escenario Flow C
      if (flow.flow_request == "Codes_Agent_Fee") {
        // MENUITEMS.push({
        //   state: "admin-panel/prices",
        //   name: "Mis precios",
        //   type: "link",
        //   icon: "local_offer",
        // });

        MENUITEMS.push({
          state: "admin-panel/comission",
          name: "Mi comisión",
          type: "link",
          icon: "monetization_on",
        });
      }

      //Menu Second Scenario Flow A
      if (flow.flow_request == "Just_Codes_Prepay") {
        MENUITEMS.push({
          state: "admin-panel/uploads",
          name: "Registrar alumnos",
          type: "link",
          icon: "sort",
        });
      }
      //console.log(user);
    }
    //console.log(MENUITEMS)
    this.menuItems = MENUITEMS;
  }
}
