<div class="section-gap pt-3 pb-0">
  rebuild slider
<!-- <ngx-slick-carousel class="carousel home-2-slider" [config]="slideConfig">
  <div ngxSlickItem *ngFor="let slide of slides">
    <div class="relative">
      <img [src]="slide.img" alt="" width="100%">
      <div class="home-2-slider-content">
        <div [innerHTML]="slide?.content"></div>
        <button color="accent" mat-raised-button [routerLink]="['/products']">Shop Now</button>
      </div>
   </div>
  </div>
</ngx-slick-carousel> -->
</div>
